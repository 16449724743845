import url from 'url';

export const VDP_PATH = '/cars-for-sale/vehicle';

export function vdpBaseUrlBuilder(vdpUrl, options = {}) {

    const { query, hash } = options;

    const parsedUrl = url.parse(vdpUrl, true);

    // delete search so we can mess with the query
    delete parsedUrl.search;

    // extend the original query with any new query data
    Object.assign(parsedUrl.query, query);

    if (hash) {
        parsedUrl.hash = hash;
    }

    if (parsedUrl.pathname && parsedUrl.pathname.endsWith('vehicle')) {
        parsedUrl.pathname += `/${parsedUrl.query.listingId}`;
    }

    parsedUrl.query = Object.entries(parsedUrl.query).reduce((acc, [key, value]) => {
        // remove listingId from query
        // remove undefined from query
        if (key !== 'listingId' && value !== undefined) {
            acc[key] = value;
        }
        return acc;
    }, {});

    let vdpPath = url.format(parsedUrl);

    // handle the insanity of kbb's trailing slash addition from getPath()
    if (vdpPath.includes('vehicle//')) {
        vdpPath = vdpPath.replace('vehicle//', 'vehicle/').replace('?', '/?');
    }

    return vdpPath;
}

export function getRelativeVdpUrlWithListingId(listingId) {
    return vdpBaseUrlBuilder(VDP_PATH, {
        query: { listingId },
    });
}

export function getRelativeVdpUrl(options) {
    return vdpBaseUrlBuilder(VDP_PATH, options);
}

export function getAbsolutePathWithListingId(origin, listingId) {
    if (origin && listingId) {
        return origin + getRelativeVdpUrlWithListingId(listingId);
    }

    return null;
}

export function vdpUrlBuilder({ baseUrl = '', clickType, tileClick, anchor }) {

    const vdpBaseUrl = baseUrl
        .replace('isNewSearch%3Dtrue', 'isNewSearch%3Dfalse')
        .replace('redirectExpiredPage=1', '')
        .replace('redirectExpiredPage%3D1', '');

    const options = {
        query: {},
    };

    if (clickType) {
        options.query.clickType = clickType;
    }

    if (tileClick) {
        options.query.tileClick = tileClick;
    }

    if (anchor) {
        options.hash = anchor;
    }

    return vdpBaseUrlBuilder(vdpBaseUrl, options);
}

export default vdpUrlBuilder;
