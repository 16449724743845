import _get from 'lodash/get';

import { fetchJSON } from '@bonnet/next/fetch';

import { DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { vdpDuckPreset } from '@/ducks/presets';

const JR2 = 'jcr:content/par/dealerdiff.2.json';
const FETCH_OPTIONS = {
    circuitBreaker: {
        id: 'dealerdiffInfo',
        timeout: 5000,
        resetTimeout: 300000,
        fallback: {},
    },
};

const HTML_FETCH_OPTIONS = {
    circuitBreaker: {
        id: 'dealerDiffHtml',
        timeout: 5000,
        resetTimeout: 300000,
        fallback: {},
    },
};

function getImageSize(imgElement) {
    const img = new Image();
    return new Promise((resolve, reject) => {
        // Set up the onload handler to get the image dimensions
        img.onload = () => {
            imgElement.width = img.naturalWidth;
            imgElement.height = img.naturalHeight;
            resolve(imgElement);
        };
        // Handle errors, e.g., image not found
        img.onerror = () => {
            reject(new Error('Failed to load image at ' + imgElement.getAttribute('src')));
        };
        // Start loading the image
        img.src = imgElement.getAttribute('src');
    });
}

const vdpDealerDiffDuck = objectDuckCreator({
    ...vdpDuckPreset,
    store: 'dealerDiff',
    initialState: {
        content: {},
    },
}).extend({
    creators: (duck) => ({
        loadDealerDiff: (query, aemHost) => async (dispatch) => {
            // TODO: the proxy should be removed and replace with new content endpoint instead
            const infoEndpoint = `/cars-for-sale/content/autotrader/dealer_diff/${query.dealerDiffCmsId}/autotrader/${query.listingType}/${JR2}`;
            const htmlEndpoint = `${aemHost}/content/autotrader/dealer_diff/${query.dealerDiffCmsId}/autotrader/${query.listingType}.html`;

            const data = (await fetchJSON(infoEndpoint, FETCH_OPTIONS)) || {};
            const rawHtmlData = (await fetch(htmlEndpoint, HTML_FETCH_OPTIONS).then((res) => res.text())) || '';

            const contentDocument = new DOMParser().parseFromString(rawHtmlData, 'text/html');

            // included css will start with relative path / and pointed to current domain instead of content[-preprod].autotrader.com, find any not start aemhost and replace it
            const linkElements = contentDocument.getElementsByTagName('link');
            Array.from(linkElements).forEach((linkElement) => {
                const href = linkElement.getAttribute('href');
                const updatedHref = aemHost + href;
                linkElement.setAttribute('href', updatedHref);
            });

            // Starting set width/height for each image existing with different size/aspect ratio
            const imgElements = contentDocument.getElementsByTagName('img');
            await Promise.all(Array.from(imgElements).map((imgElement) => getImageSize(imgElement)));

            const cta = {
                label: data.ctaLinkText || '',
                offsite: data.ctalinkInternalExternal || '',
                link: data.ctaLinkUrl || '',
            };
            const logo = {
                src: `${aemHost}/${_get(data, 'logoImage.fileReference', '')}`,
            };

            dispatch(duck.creators.setKeys({
                content: {
                    cta,
                    htmlContent: contentDocument.documentElement.innerHTML,
                    logo,
                },
            }));
        },
    }),
    selectors: () => ({
        getContent: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).content),
    }),
});

export default vdpDealerDiffDuck;
