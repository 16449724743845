import React, { memo } from 'react';

import PropTypes from 'prop-types';

import {
    AdSlot,
} from 'reaxl-ads';
import { useFeatures } from 'reaxl-features';

const AtcAdSlot = memo(({
    advertisementLabel,
    isGlobalAdsPlaceholderOff,
    preserveSpace,
    showLabel,
    showPlaceholder,
    slotId,
    ...rest
}) => {
    const { preserve_ad_space: [preserveAdSpace] } = useFeatures(['preserve_ad_space']);
    return (
        <AdSlot
            advertisementLabel={showLabel ? advertisementLabel : { className: 'hidden' }}
            extra={{
                'data-qa-data': JSON.stringify({ ...rest, slotId }),
            }}
            preserveMaxSize={preserveAdSpace || preserveSpace}
            showPlaceholder={showPlaceholder}
            slotId={slotId}
            {...rest}
        />
    );
});

AtcAdSlot.propTypes = {
    advertisementLabel: PropTypes.object,
    className: PropTypes.string,
    extra: PropTypes.object,
    lazyLoading: PropTypes.bool,
    lazyLoadingBottomOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lazyLoadingTopOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    preserveSpace: PropTypes.bool,
    showLabel: PropTypes.bool,
    size: PropTypes.array.isRequired,
    slotId: PropTypes.string.isRequired,
    showPlaceholder: PropTypes.bool,
};

AtcAdSlot.defaultProps = {
    advertisementLabel: {
        className: 'text-right text-size-5',
        text: 'Advertisement',
    },
    className: 'margin-horizontal-auto margin-bottom-4',
    //  Using 300 for offsets to mirror LazyRender
    lazyLoadingBottomOffset: '-300',
    lazyLoadingTopOffset: '-300',
    preserveSpace: false,
    showLabel: true,
    showPlaceholder: true,
};

export default AtcAdSlot;
