import _get from 'lodash/get';

export function getDealerDetailUrl(owner, ddpBasePath = '/car-dealers') {
    const { state, city } = _get(owner, 'location.address', {});

    if (state && city && owner.id) {
        // Removes spaces and special characters from city name and owner names
        const cityName = city.replace(/[^A-Za-z0-9\s]/gi, '').replace(/[\s-]+/g, '-');
        const urlOwnerName = owner.name.replace(/[^A-Za-z0-9\s]/gi, ' ').replace(/\s\s+/g, ' ').trim().split(' ')
            .join('-');

        const href = `${ddpBasePath}/${cityName}-${state}/${owner.id}/${urlOwnerName}/`;
        return {
            href: href.toLowerCase(),
        };
    }
    return null;
}

export default getDealerDetailUrl;
